<template>
  <v-container fluid>
    <v-card outlined>
      <v-tabs v-model="tab">
        <v-tab v-for="group in filterGroups" :key="group.value">
          {{ group.text }}
        </v-tab>
      </v-tabs>
    </v-card>

    <v-card outlined class="pa-5 mt-3">
      <v-toolbar flat>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          outlined
          dense
          class="shrink search"
          :class="$vuetify.breakpoint.xsOnly ? 'seachXs' : ''"
          :placeholder="$t('search')"
          :label="$t('search')"
          single-line
          hide-details
        />
        <v-spacer></v-spacer>
        <v-btn
          :v-if="$store.getters.isInRole(50)"
          color="primary"
          elevation="0"
          class="ma-1"
          @click="dialog = true"
        >
          {{ $t("add") }}

          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </v-toolbar>

      <data-table-component
        :headers="headers"
        :items="services"
        :search="search"
        :loading="loading"
      />
      <v-dialog v-model="dialog" persistent max-width="500px">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-toolbar color="primary darken-1" dark>
              <v-card-title>
                <span class="">{{
                  (editedIndex === -1 ? $t("add") : $t("edit")) +
                  " " +
                  $t("services.service")
                }}</span>
              </v-card-title>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.serviceName"
                      :rules="rules"
                      :label="$t('services.serviceName')"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.serviceNote"
                      :label="$t('notes')"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="green darken-1"
                class="white--text"
                @click="save"
                :disabled="!valid"
                :min-width="100"
              >
                <v-icon>mdi-content-save-outline</v-icon>
                {{ $t("save") }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="close">
                {{ $t("cancel") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-card>

    <confirm-dialog
      :openDialog="dialogDelete"
      :onClicked="deleteItemConfirm"
      :onClose="closeDelete"
    />
  </v-container>
</template>
<script>
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";

export default {
  components: { ConfirmDialog, DataTableComponent },
  data() {
    return {
      tab: 0,

      valid: true,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      search: "",

      services: [],
      editedItem: {
        serviceName: "",
        serviceGroup: 0,
        serviceNote: "",
      },
      defaultItem: {
        serviceName: "",
        serviceGroup: 0,
        serviceNote: "",
      },
      groups: [
        {
          text: this.$t("services.serviceGroupType.general"),
          value: 0,
          role: "65",
        },
        {
          text: this.$t("services.serviceGroupType.dental"),
          value: 1,
          role: "66",
        },
        {
          text: this.$t("services.serviceGroupType.operation"),
          value: 2,
          role: "67",
        },
      ],
      rules: [(value) => !!value || "Required."],
    };
  },
  created() {
    this.refreshTable();
  },

  computed: {
    headers() {
      var list = [
        { text: this.$t("services.serviceName"), value: "serviceName" },
        {
          text: this.$t("services.serviceGroup"),
          value: "serviceGroup",
          type: "select",
          items: this.groups,
          chip: false,
        },
        { text: this.$t("notes"), value: "serviceNote" },
      ];
      var actions = {
        text: "",
        value: "actions",
        templates: [],
      };

      if (this.$store.getters.isInRole(51)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-pencil",
          text: this.$t("edit"),
          click: this.editItem,
        });
      }
      if (this.$store.getters.isInRole(52)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-delete",
          text: this.$t("delete"),
          click: this.deleteItem,
          color: "red darken-2",
        });
      }
      list.push(actions);
      return list;
    },
    filterGroups() {
      return this.groups.filter((f) => this.isInRole(f.role));
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    tab(val) {
      this.refreshTable();
      this.editedItem.serviceGroup = this.filterGroups[val].value;
    },
    pageLength(val) {
      if (this.page > val) this.page = val;
    },
  },

  methods: {
    getText(i) {
      var result = this.groups.filter((obj) => {
        return obj.value == i;
      });
      return result[0].text;
    },
    editItem(item) {
      //console.log(item);
      this.editedIndex = this.services.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.services.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      var deleteItem = this.services[this.editedIndex];

      this.$axios
        .delete("Service/Delete?guid=" + deleteItem.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }

          this.refreshTable();
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    refreshTable() {
      this.$axios
        .get("Service/Get?group=" + this.filterGroups[this.tab].value)
        .then((response) => {
          this.services = response.data.data;
        });
    },

    save() {
      var val = this.$refs.form.validate();
      if (val) {
        if (this.editedIndex > -1) {
          var updatedItem = this.services[this.editedIndex];
          updatedItem.serviceName = this.editedItem.serviceName;
          // updatedItem.serviceGroup = this.editedItem.serviceGroup;
          updatedItem.serviceNote = this.editedItem.serviceNote;

          this.$axios
            .post("Service/Update", updatedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable();
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              console.log(e);
            });
          Object.assign(this.services[this.editedIndex], this.editedItem);
        } else {
          this.editedItem.serviceGroup = this.filterGroups[this.tab].value;
          this.$axios
            .post("Service/Add", this.editedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable();
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              console.log(e);
            });
        }
      }
    },
  },
};
</script>

<style></style>
